body {
  margin: 0;
  font-family: "Bitter", system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
  height: 80vh;
  width: 100%;
}

.meeting-point {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.input-container {
  background-color: #f3efeb;
  padding: 1rem;
  position: relative;
}

.input {
  border: none;
  background-color: #f3efeb;
  outline: none;
  font-family: "Bitter", system-ui;
  font-size: 16px;
}

.button {
  border: 1px solid;
  padding: 10px 25px 10px 25px;
  border-radius: 25px;
  cursor: pointer;
}

.drop-down{
  display:flex;
  flex-direction: row;
  
}

.chevron {
  width: 20px;
  margin-left: 10px;
}

.mapboxgl-ctrl-geocoder--pin-right {
  display: none;
}

.mapboxgl-ctrl-geocoder {
  position: relative; /* Relative to parent container, necessary for absolute dropdown */
}

.mapboxgl-ctrl-geocoder--input {
  border: none;
  background-color: #f3efeb;
  outline: none;
  font-family: "Bitter", system-ui;
  font-size: 16px;
}

.suggestions-wrapper .suggestions{
  position: absolute !important; /* Make the suggestions dropdown overlay */
  z-index: 9999 !important; /* Ensure it stays above other content */
  top: 100%; /* Position it directly below the input */
  left: 0; /* Align it to the left edge */
  width: 100% !important; /* Make the suggestions dropdown the same width as the input */
  background-color: white; /* Ensure a white background for the dropdown */
  border: 1px solid #ccc; /* Optional: Add a border */
  max-height: 300px; /* Set a max height for the dropdown */
  overflow-y: auto; /* Allow scrolling if suggestions overflow */
}

.suggestions-wrapper .suggestions li {
  list-style-type: none; /* Removes the bullet points */
  padding: 10px; /* Optional: Add padding for better spacing */
  cursor: pointer; /* Make the list items clickable */
}

/* Add a divider between each suggestion */
.suggestions-wrapper .suggestions li:not(:last-child) {
  border-bottom: 1px solid #ddd; /* Adds a divider between each list item */
}

/* Optional: Hover effect for better user experience */
.suggestions-wrapper .suggestions li:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1000; /* Ensure it stays on top of the map */
}




